import React from 'react';

const Footer = () => {
    return (
        <div>
            <h3>This is copyright.</h3>
        </div>
    );
};

export default Footer;